import React, { Component } from 'react';
import Page from './page';
import AppBar from '../common/AppBar';
import {
    setItemStorage, getItemStorage,
} from '../../utils/LocalStorageManager';
import {
    GetSocios, PostSocio,PutSocio,DeleteSocio
} from '../../utils/SocioManager';
import {
    UsuarioLogueado
} from '../../utils/UserManager';

function convertirFechasNacimiento(socios) {
    return socios.map(socio => {
        // Crear una copia para no mutar el original
        const nuevoSocio = { ...socio };

        // Convertir fechaNacimiento a Date manualmente
        if (nuevoSocio.fechaNacimiento) {
            const [year, month, day] = nuevoSocio.fechaNacimiento.split('-').map(Number); // Separar y convertir
            const fecha = new Date(year, month - 1, day); // Crear la fecha correctamente
            if (!isNaN(fecha.getTime())) {
                nuevoSocio.fechaNacimiento = fecha; // Asignar la fecha válida
            } else {
                console.warn(`Fecha inválida para socio con ID ${nuevoSocio.id || 'desconocido'}`);
                nuevoSocio.fechaNacimiento = null; // Manejo de error
            }
        }

        return nuevoSocio;
    });
}
class Alumnos extends Component {
    constructor(props) {
        super(props);
        this.state = {socios :[],show:false}
        this.goTo = this.goTo.bind(this);
    }

    componentDidMount() {
    if(!UsuarioLogueado()){
        window.location.href = "/login";
    } else
    {
     let socios = convertirFechasNacimiento(GetSocios());
     console.log(socios);
     this.setState({socios, show:true}) 
    }
    }

    goTo(path) {
        this.props.history.push(path);
    }

    render() {
        const {
            currentItem,
        } = this.props;

        return (
            <React.Fragment>
            <AppBar DrawerOpenedDefault={true} goTo={this.goTo} path='/socios' />
             <center>
             <img src="/static/owen-vigila.png" width="150" height="auto" /> 
             </center>
            {this.state.show &&<Page
                currentItem={currentItem}
                socios={this.state.socios}
                onCreate={PostSocio}
                onUpdate={PutSocio}
                onDelete={DeleteSocio}
                goTo={this.goTo}
            />}
            {!this.state.show && <span>Cargando socios...</span>}
            </React.Fragment>
        );
    }
}


export default Alumnos;